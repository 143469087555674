import { getSteppedPriceFactory } from '@hiraeth/protocol';
import type { SupportedBlockverseChainId } from '@pob/blockverse';
import { useMemo } from 'react';
import { useHiraethChainStateForChain } from '../providers/HiraethChainState';

export const useGetCurrentPriceByIndex = (
  chainId: SupportedBlockverseChainId<'hiraeth'> | undefined,
) => {
  const { hiraethProtocolParameters, mintingStateRes } =
    useHiraethChainStateForChain(chainId);

  const getCurrentPriceByIndex = useMemo(() => {
    if (!hiraethProtocolParameters) {
      return undefined;
    }
    if (!mintingStateRes?.data) {
      return undefined;
    }
    const getCurrentPrice = getSteppedPriceFactory(
      hiraethProtocolParameters.defaultMintingGroup.mintingParameters,
    );
    return (i: number) =>
      getCurrentPrice(
        mintingStateRes.data.totalMintedInDefaultMintingGroup + BigInt(i),
      );
  }, [mintingStateRes, hiraethProtocolParameters]);

  return getCurrentPriceByIndex;
};
