import { Variant, Variants } from 'framer-motion';

export const OPACITY_VARIANTS: Variants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};

export const PAGE_CONTENT_VARIANTS: Variants = {
  initial: {
    y: -40,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
  },
  exit: {
    y: 40,
    opacity: 0,
  },
};

export const TRANSITION_VARIANTS: Variants = {
  initial: {
    y: 12,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
  },
  exit: {
    y: -12,
    opacity: 0,
  },
};

export const MINIMIZE_CONTENT_VARIANTS: Variants = {
  initial: {
    height: 0,
    opacity: 0,
  },
  animate: {
    height: 'fit-content',
    opacity: 1,
    transition: { duration: 0.12, ease: 'easeInOut' },
  },
  exit: {
    height: 0,
    opacity: 0,
    transition: { duration: 0.12, ease: 'easeInOut' },
  },
};

export const USE_VARIANT_PROPS = {
  initial: 'initial',
  animate: 'animate',
  exit: 'exit',
};

export type InViewVariantTypes = 'hide' | 'show';

export const ANIMATE_IN_VARIANTS: Record<InViewVariantTypes, Variant> = {
  hide: {
    y: '4rem',
    opacity: 0,
  },
  show: {
    y: 0,
    opacity: 1,
  },
};

export const USE_VARIANT_IN_VIEW_PROPS = {
  initial: 'hide',
};
