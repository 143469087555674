// import { SVG_FILL_COLOR } from '../../styles/defaultStyles';
// import { SoftBaseBox } from '../box';
// import { SquareActionButton } from '../button';
import { Dialog as ArkDialog } from '@ark-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import type { FC } from 'react';
import { css } from '~src/styled-system/css';
import { styled } from '~src/styled-system/jsx';
import { forwardClassName } from '~src/utils/panda/forwardClassName';
import { layeredBoxGroupCss } from '../box/layered';

export const dialogBackgroundCss = css.raw({
  // backdropFilter: 'blur(4px)',
  bg: 'darken',
  // background: {
  //   _light: 'white.a10',
  //   _dark: 'black.a10',
  // },
  height: '[100vh]',
  left: 'zero',
  position: 'fixed',
  top: 'zero',
  width: '[100vw]',
  zIndex: 'overlay',
  _open: {
    animation: 'backdrop-in',
  },
  _closed: {
    animation: 'backdrop-out',
  },
});

export const dialogPositionerCss = css.raw({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  left: 'zero',
  overflow: 'auto',
  position: 'fixed',
  top: 'zero',
  width: '[100vw]',
  height: '[100dvh]',
  zIndex: 'modal',
});

export const dialogContentBaseCss = css.raw({
  // background: 'bg.default',
  // borderRadius: 'l3',
  // boxShadow: 'lg',
  // minW: 'sm',
  position: 'relative',
  _open: {
    animation: 'dialog-in',
  },
  _closed: {
    animation: 'dialog-out',
  },
  width: {
    base: '[calc(100vw - 2 * {spacing.box})]',
    xs: 'xs',
  },
});

const Positioner = forwardClassName(ArkDialog.Positioner)(
  css(dialogPositionerCss),
);

const DialogContentStage: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <AnimatePresence mode="wait" initial={false}>
      {children}
    </AnimatePresence>
  );
};

const DialogStage: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <motion.div
    // initial={{ opacity: 0, scale: 0.9, y: 20 }}
    // animate={{ opacity: 1, scale: 1, y: 0 }}
    // exit={{ opacity: 0, scale: 0.9, y: -20 }}
    // transition={{
    //   duration: 0.2,
    //   // ease: 'easeInOut',
    // }}
    >
      {children}
    </motion.div>
  );
};

export const Dialog = {
  ...ArkDialog,
  Content: forwardClassName(ArkDialog.Content)(css(dialogContentBaseCss)),
  Backdrop: forwardClassName(ArkDialog.Backdrop)(css(dialogBackgroundCss)),
  Positioner,
  BareContent: ArkDialog.Content,
  BarePositioner: ArkDialog.Positioner,
  LayeredBoxGroup: styled('div', {
    base: css.raw(layeredBoxGroupCss, {
      bg: 'background',
    }),
  }),
  ContentStage: DialogContentStage,
  Stage: DialogStage,
};

// export interface ModalProps {
//   isOpen?: boolean;
//   closeModal?: () => void;
// }

// export const Modal = styled(SoftBaseBox)`
//   background-color: ${(p) => p.theme.gray.200};
//   width: ${(p) => p.theme.breakpoints.upToExtraSmall}px;
//   display: grid;
//   grid-template-columns: 1fr;
//   position: relative;
//   @media (max-width: ${(p) => p.theme.breakpoints.upToExtraSmall}px) {
//     width: calc(100% - ${(p) => p.theme.padding.box * 2}px);
//   }
// `;

// export const ModalCloseButton = styled(SquareActionButton)`
//   ${(p) => SVG_FILL_COLOR(p.theme.gray.600)};
//   > svg {
//     height: 16px;
//     width: 16px;
//   }
// `;

// export const ModalOverlay = styled(motion.div)`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   flex-direction: column;
//   background-color: rgba(0, 0, 0, 0.4);
//   position: fixed;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   z-index: 9999;
// `;

// export const MODAL_VARIANTS: Variants = {
//   initial: {
//     opacity: 0,
//     y: -20,
//   },
//   animate: {
//     opacity: 1,
//     y: 0,
//   },
//   exit: {
//     opacity: 0,
//     y: -20,
//   },
// };
