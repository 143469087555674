import { FC, useMemo } from 'react';
import { useUserHandleFromExtAccount } from '~src/hooks/user/useUserHandleFromExtAccount';
import { Box, Flex } from '~src/styled-system/jsx';
import { useHiraeth } from '../../../providers/Hiraeth';
import {
  BaseHiraethRow,
  HiraethRowLabel,
  HiraethRowLabelContainer,
} from './common';
import type { SystemStyleObject } from '~src/styled-system/types';

export const HiraethColorRow: FC<{
  label?: string;
  slots?: {
    row?: SystemStyleObject;
  };
}> = ({ label, slots }) => {
  const { metadata, txnHash } = useHiraeth();

  const colorPalette = useMemo(() => {
    if (!metadata) {
      return undefined;
    }
    const numColorsInRamp = metadata.colorPalette.numColorsInRamp;
    const slicedColorRamp = metadata.colorPalette.colors.slice(
      (metadata.colorPalette.numBackgroundColorRamps - 1) * numColorsInRamp,
      metadata.colorPalette.numBackgroundColorRamps * numColorsInRamp,
    );
    return slicedColorRamp;
  }, [metadata]);

  return (
    <BaseHiraethRow css={slots?.row}>
      <HiraethRowLabelContainer flx={'flexRowEnds'}>
        <HiraethRowLabel>{label ?? 'Color Palette'}</HiraethRowLabel>
      </HiraethRowLabelContainer>
      <Flex
        mt={'halfIncrement'}
        flx={'flexRow'}
        height={'icon.eighteen'}
        alignItems={'stretch'}
        rounded={'innerOfSquaredButton'}
        overflow={'hidden'}
      >
        {colorPalette?.map((color, i) => (
          <Box
            key={`${txnHash}-color-${i}`}
            style={{
              backgroundColor: `rgb(${color[0]}, ${color[1]}, ${color[2]})`,
            }}
            flexGrow={1}
          />
        ))}
      </Flex>
    </BaseHiraethRow>
  );
};
