import { resolveURL } from 'ufo';
import { Evm } from '@pob/shared';

export const getBlockExplorerCollectionUrl = (
  baseUri: string,
  addr: Evm.Address,
) => {
  return resolveURL(baseUri, 'token', addr);
};

export const getBlockExplorerTxUrl = (baseUri: string, txHash: Evm.TxnHash) => {
  return resolveURL(baseUri, 'tx', txHash);
};

export const getBlockExplorerAddressUrl = (
  baseUri: string,
  addr: Evm.Address,
) => {
  return resolveURL(baseUri, 'address', addr);
};

export const getBlockExplorerBlockUrl = (
  baseUri: string,
  blockNumber: string,
) => {
  return resolveURL(baseUri, 'block', blockNumber);
};
