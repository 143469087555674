import { ZERO } from '@pob/shared';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import type { CheckoutMintProps, CheckoutOnChainCall } from '../types';

export const useOnChainCalls = (mintProps?: CheckoutMintProps) => {
  return useQuery({
    queryKey: useMemo(
      () => ['mintCalls', ...(mintProps?.key ?? [])], //...Object.values(mintProps).map((p) => p?.id)],
      [mintProps],
    ),
    queryFn: async () => {
      const calls: CheckoutOnChainCall[] = [];
      if (!mintProps) {
        return calls;
      }
      const callsForProject = await mintProps.prepareOnChainCalls();
      if (!callsForProject) {
        return; // continue;
      }
      calls.push(...callsForProject);
      // }
      return calls;
    },
  });
};

export const useOnChainCallsGasLimit = (calls?: CheckoutOnChainCall[]) => {
  return useMemo(() => {
    if (!calls) {
      return undefined;
    }
    const totalGasLimit = calls.reduce((acc, call) => {
      return acc + (call.gasLimit ?? ZERO);
    }, 0n);
    return (
      totalGasLimit +
      DEFAULT_CHECKOUT_PER_CALL_GAS_LIMIT_BUFFER * BigInt(calls.length) +
      DEFAULT_CHECKOUT_BASE_GAS_LIMIT_BUFFER
    );
  }, [calls]);
};

export const DEFAULT_CHECKOUT_PER_CALL_GAS_LIMIT_BUFFER = 10000n;

export const DEFAULT_CHECKOUT_BASE_GAS_LIMIT_BUFFER = 25000n;
