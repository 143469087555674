import type { FC, ReactNode } from 'react';
import { DisplayP } from '~src/components/text';
import { css } from '~src/styled-system/css';
import { Flex, styled } from '~src/styled-system/jsx';

export const BaseHiraethRow = styled('div', {
  base: {
    bg: 'white',
    // borderRadius: 'innerBorderRadius',
    p: 'innerBox',
    rounded: 'innerOfInnerBox',
  },
});

export const HiraethRowLeftContent = styled('div', {
  base: {
    flexGrow: '1',
  },
});

export const HiraethRowLabel = styled('p', {
  base: {
    color: 'gray.400',
    textStyle: 'p.display',
  },
});

export const HiraethRowValue = styled('p', {
  base: {
    textStyle: 'p.display',
    fontSize: 'p.display.reading',
    fontWeight: 'display.bold',
  },
});

export const HiraethRowValueContainer = styled('div', {
  base: {
    flx: 'flexRow',
  },
});

export const HiraethRowLabelContainer = styled('div', {
  base: {
    flx: 'flexRow',
    justifyContent: 'justify-content',
    // marginBottom: 'zero',
  },
});

export const HiraethAttributeLabel = styled('p', {
  base: {
    color: 'gray.400',
    lineHeight: 'singleLine',
    fontSize: 'p.display.caption',
    textAlign: 'right',
  },
});

export const HiraethAttributeValue = styled('p', {
  base: {
    flx: 'flexRow',
    mt: 'halfIncrement',
    lineHeight: 'singleLine',
    textAlign: 'right',
    color: 'gray.600',
  },
});

const hiraethAttributeWellCss = css.raw({
  p: 'increment',
  border: '1px solid',
  bg: 'white',
  shadow: 'soft',
  borderColor: 'gray.100',
  rounded: 'innerOfInnerBox',
});

export const HiraethAttributeWell = styled('div', {
  base: hiraethAttributeWellCss,
});

export const SigilImageContainer = styled('div', {
  base: css.raw(hiraethAttributeWellCss, {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
});

export const DetailsLabel: FC<{
  label: ReactNode;
}> = ({ label }) => {
  return (
    <Flex justify={'space-between'} pb={'increment'}>
      <DisplayP.Default color={'gray.400'}>{label}</DisplayP.Default>
    </Flex>
  );
};
