import { FC, useMemo } from 'react';
import { useBestDisplayName } from '~src/hooks/address/useBestDisplayName';
import { useBlockExplorerAddressUrl } from '~src/hooks/url/useExternalUrls';
import { getBase64PlaceholderImage } from '~src/utils/image/placeholder';
import { useHiraethCrest } from '../../../hooks/useHiraethCrest';
// import { useHiraethHoverStateContext } from '../../../providers/HiraethHoverState';
import type { Evm } from '@pob/shared';
import { A } from '~src/components/anchor';
import { SuperStrong } from '~src/components/text/spans';
import { Tooltip, tooltipTimingProps } from '~src/components/tooltip';
import { css } from '~src/styled-system/css';
import { VStack, styled } from '~src/styled-system/jsx';
import type { SystemStyleObject } from '~src/styled-system/types';
import { isEqAddress } from '~src/utils/bytes/isEqAddress';
import { useHiraeth } from '../../../providers/Hiraeth';
import { useHiraethHoverState } from '../../../providers/HiraethHoverState';
import { HiraethColorRow } from './color';
import {
  BaseHiraethRow,
  HiraethRowLabel,
  HiraethRowLabelContainer,
  HiraethRowValue,
  HiraethRowValueContainer,
  SigilImageContainer,
} from './common';
import { useDestinationAddress } from '../../../hooks/useDestinationAddress';
export type StatelessHiraethAddressRowProps = {
  address?: Evm.Address;
  label: string | undefined;
  // css?: SystemStyleObject;
  slots?: {
    row?: SystemStyleObject;
    textContainer?: SystemStyleObject;
  };
  shouldEnableHover?: boolean;
};

export const StatelessHiraethAddressRow: FC<
  StatelessHiraethAddressRowProps
> = ({ address, label, slots, shouldEnableHover }) => {
  const crestSigilRes = useHiraethCrest(address, { shouldUseColor: false });
  const crestUri = useMemo(() => {
    if (!crestSigilRes.data) {
      return getBase64PlaceholderImage(24, 24, 'transparent');
    }
    return crestSigilRes.data.uri;
  }, [crestSigilRes]);

  const bestName = useBestDisplayName(address);
  const blockExplorerUrl = useBlockExplorerAddressUrl(address);

  const { setHoverState } = useHiraethHoverState();

  return (
    <BaseHiraethRow
      onMouseEnter={() => {
        if (!shouldEnableHover) return;
        if (!address) return;
        setHoverState({
          type: 'crest',
          address,
        });
      }}
      onMouseLeave={() => {
        if (!shouldEnableHover) return;
        setHoverState(null);
      }}
      flx={'flexRowEnds'}
      gap={'increment'}
      css={slots?.row}
    >
      <VStack gap={'zero'} alignItems={'flexStart'} css={slots?.textContainer}>
        {label && (
          <HiraethRowLabelContainer>
            <HiraethRowLabel>{label}</HiraethRowLabel>
          </HiraethRowLabelContainer>
        )}
        <HiraethRowValueContainer>
          <HiraethRowValue>
            <A hover={'underline'} href={blockExplorerUrl} target={'_blank'}>
              {bestName ?? '-'}
            </A>
          </HiraethRowValue>
        </HiraethRowValueContainer>
      </VStack>
      <SigilImageContainer>
        <styled.img
          imageRendering="pixelated"
          display="block"
          height={'[26px]'}
          aspectRatio={'crest'}
          src={crestUri}
        />
      </SigilImageContainer>
    </BaseHiraethRow>
  );
};

export const StatelessHiraethAddressRowWithColor: FC<
  StatelessHiraethAddressRowProps & {
    shouldShowColorPaletteIfSameAsFromOrDestination?: boolean;
  }
> = (props) => {
  const { address, shouldShowColorPaletteIfSameAsFromOrDestination=true } = props;
  const { metadata } = useHiraeth();
  const destinationAddress = useDestinationAddress();

  const shouldShowColorPalette = useMemo(() => {
    if (!address) return false;
    if (!metadata) return false;
    if (metadata.additionalMetadata.version === '1.0.0') {
      return false;
    }
    if (
      isEqAddress(destinationAddress, address) &&
      !shouldShowColorPaletteIfSameAsFromOrDestination
    ) {
      return false;
    }
    return isEqAddress(metadata.additionalMetadata.colorSource, address);
  }, [address, metadata]);

  return (
    <>
      <StatelessHiraethAddressRow
        {...props}
        slots={{
          ...props.slots,
          row: css.raw({
            roundedBottom: 'zero',
            zIndex: '[2]',
            pos: 'relative',
          }),
        }}
      />
      {shouldShowColorPalette && (
        <>
          <Tooltip.Root
            {...tooltipTimingProps.slowOpen}
            positioning={{
              placement: 'bottom',
            }}
          >
            <Tooltip.Trigger>
              <HiraethColorRow
                slots={{
                  row: css.raw({
                    mt: '-innerBox',
                    roundedTop: 'zero',
                    zIndex: '[1]',
                    pos: 'relative',
                  }),
                }}
              />
            </Tooltip.Trigger>
            <Tooltip.Positioner>
              <Tooltip.Content
                style={{
                  maxWidth: 220,
                }}
              >
                <Tooltip.BoxGrid>
                  <Tooltip.Box>
                    <Tooltip.P>
                      Colors are derived from the most important{' '}
                      <SuperStrong>address</SuperStrong> interacted with in the
                      txn.
                    </Tooltip.P>
                  </Tooltip.Box>
                </Tooltip.BoxGrid>
              </Tooltip.Content>
            </Tooltip.Positioner>
          </Tooltip.Root>
        </>
      )}
    </>
  );
};
