import { useMemo } from 'react';
import { useAccount, useBalance } from 'wagmi';
import { useMounted } from '~src/hooks/ui/useMounted';
import {
  usePreferredChain,
  usePreferredWalletClientState,
} from '~src/providers/PreferredNetwork';
import type { CheckoutPriceProps } from '../../types';
import type { CheckoutErrorMap } from '../../types/errors';

export const useInsufficientFundsError = (priceProps?: CheckoutPriceProps) => {
  const preferredChain = usePreferredChain();
  const walletClientState = usePreferredWalletClientState();
  const isMounted = useMounted();
  const { address } = useAccount();
  const balanceRes = useBalance({ address, chainId: preferredChain.id });
  const insufficientFundsError = useMemo(():
    | CheckoutErrorMap['insufficient-funds']
    | undefined => {
    // hide insufficient funds if not connected to correct chain
    if (walletClientState === 'not-preferred-chain') {
      return undefined;
    }
    if (!isMounted) {
      return undefined;
    }
    if (!balanceRes.data) {
      return undefined;
    }
    if (!priceProps?.finalTotalPrice) {
      return undefined;
    }
    if (balanceRes.data.value < priceProps.finalTotalPrice) {
      return {
        source: 'global',
        type: 'insufficient-funds',
        isCheckoutBlocking: false,
        isMintBlocking: true,
        hideActionOnMint: false,
      };
    }
    return undefined;
  }, [walletClientState, priceProps, isMounted, balanceRes]);

  return insufficientFundsError;
};
