import type { Evm } from '@pob/shared';
import { useMemo } from 'react';
import { useHiraeth } from '../providers/Hiraeth';

export const useDestinationAddress = () => {
  const { metadata } = useHiraeth();
  return useMemo(() => {
    if (!metadata) {
      return undefined;
    }
    const transaction = metadata.txnMetadata.transaction;
    const receipt = metadata.txnMetadata.receipt;
    if (!!transaction.to) {
      return transaction.to as Evm.Address;
    }
    if (!!receipt.contractAddress) {
      return receipt.contractAddress as Evm.Address;
    }
    return undefined;
  }, [metadata]);
};

export const useIsDeployedContractTxn = () => {
  const { metadata } = useHiraeth();
  return useMemo(() => {
    if (!metadata) {
      return false;
    }
    const receipt = metadata.txnMetadata.receipt;
    return !!receipt.contractAddress;
  }, [metadata]);
};
