import { useMemo } from 'react';
import { useIsActiveWalletBestWalletForPrivyUser } from '~src/hooks/wallet/useIsWalletActive';
import type { CheckoutErrorMap } from '../../types/errors';

export const useLinkedAccountMismatchWarning = () => {
  const isConnectedWalletLinked = useIsActiveWalletBestWalletForPrivyUser();

  const linkedAccountMismatchWarning = useMemo(():
    | CheckoutErrorMap['linked-account-mismatch']
    | undefined => {
    // hide insufficient funds if not connected to correct chain
    if (isConnectedWalletLinked || isConnectedWalletLinked === undefined) {
      return undefined;
    }
    return {
      source: 'global',
      type: 'linked-account-mismatch',
      isCheckoutBlocking: false,
      isMintBlocking: true,
      hideActionOnMint: false,
    };
  }, [isConnectedWalletLinked]);

  return linkedAccountMismatchWarning;
};
