import { FC } from 'react';
import { ICONS_BY_CHAIN_IDS } from '~src/components/chains/icons';
import { SPANS_BY_CHAIN_ID } from '~src/components/chains/spans';
import { usePreferredChain } from '~src/providers/PreferredNetwork';
import { Circle, HStack } from '~src/styled-system/jsx';
import {
  BaseHiraethRow,
  HiraethRowLabel,
  HiraethRowLabelContainer,
  HiraethRowValue,
} from './common';

export const HiraethChainRow: FC = () => {
  const chain = usePreferredChain();
  const chainId = chain.id;

  return (
    <BaseHiraethRow>
      <HiraethRowLabelContainer>
        <HiraethRowLabel>Chain</HiraethRowLabel>
      </HiraethRowLabelContainer>
      <HStack gap="increment">
        <Circle size={'icon.eighteen'} pos={'relative'} overflow={'hidden'}>
          {ICONS_BY_CHAIN_IDS[chainId]}
        </Circle>
        <HiraethRowValue>{SPANS_BY_CHAIN_ID[chainId]}</HiraethRowValue>
      </HStack>
    </BaseHiraethRow>
  );
};
