import { FC } from 'react';

export const CopyIcon: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2 20.6001C19.9732 20.6001 20.6 19.9733 20.6 19.2001L20.6 12.0001C20.6 11.2269 19.9732 10.6001 19.2 10.6001L12 10.6001C11.2268 10.6001 10.6 11.2269 10.6 12.0001L10.6 19.2001C10.6 19.9733 11.2268 20.6001 12 20.6001L19.2 20.6001ZM22.6 19.2001C22.6 21.0779 21.0777 22.6001 19.2 22.6001L12 22.6001C10.1222 22.6001 8.59997 21.0779 8.59997 19.2001L8.59998 12.0001C8.59998 10.1223 10.1222 8.6001 12 8.6001L19.2 8.6001C21.0777 8.6001 22.6 10.1223 22.6 12.0001L22.6 19.2001ZM6.99997 14.4001C6.99997 14.9524 6.55226 15.4001 5.99997 15.4001L3.59997 15.4001C2.38495 15.4001 1.39998 14.4151 1.39998 13.2001L1.39998 4.4001C1.39998 2.74325 2.74312 1.4001 4.39998 1.4001L13.2 1.4001C14.415 1.4001 15.4 2.38507 15.4 3.6001L15.4 6.0001C15.4 6.55238 14.9523 7.0001 14.4 7.0001C13.8477 7.0001 13.4 6.55238 13.4 6.0001L13.4 3.6001C13.4 3.48964 13.3104 3.4001 13.2 3.4001L4.39998 3.4001C3.84769 3.4001 3.39998 3.84781 3.39998 4.4001L3.39998 13.2001C3.39998 13.3106 3.48952 13.4001 3.59997 13.4001L5.99997 13.4001C6.55226 13.4001 6.99998 13.8478 6.99997 14.4001Z"
        fill="currentColor"
      />
    </svg>
  );
};
