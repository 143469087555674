import { usePrivy } from '@privy-io/react-auth';
import type { AvailableChainId } from '@protocol/chains';
import { useCallback, useMemo } from 'react';
import { useChainId } from 'wagmi';
import { usePrivyDialogs } from '~src/shared-apps/social/providers/PrivyDialogsProvider';

// export type RequiredAction = 'farcaster-write' | 'wallet-send';

// export const useActionsAllowedByPrivyUserState = () => {
//   const { user } = usePrivy();

//   return useMemo((): Partial<Record<RequiredAction, boolean>> => {
//     if (!user) {
//       return {};
//     }
//     return {
//       'farcaster-write': !!user.farcaster && !!user.farcaster.signerPublicKey,
//       'wallet-send': !!user.wallet,
//     };
//   }, [user]);
// };

export const useIsActionBlockedByChainForWallet = (
  requiredChain: AvailableChainId,
) => {
  const chainId = useChainId();
  return useMemo(() => {
    return chainId !== requiredChain;
  }, [chainId, requiredChain]);
};

// export const useDeferActionToUnblockPrivyUserState = (
//   requiredAction: RequiredAction,
//   action: () => Promise<void>,
// ) => {
//   const actionsAllowed = useActionsAllowedByPrivyUserState();
//   const { login, authenticated } = usePrivy();
//   const { open } = usePrivyDialogs();
//   const deferredAction = useCallback(() => {
//     if (requiredAction === 'farcaster-write') {
//       if (!authenticated) {
//         login({});
//         return;
//       }
//       if (!actionsAllowed['farcaster-write']) {
//         open();
//         return;
//       }
//     }
//     if (requiredAction === 'wallet-send') {
//       if (!authenticated) {
//         login({});
//         return;
//       }
//       console.error('no wallet found for action.');
//       // if (!actionsAllowed.wallet) {
//       //   open();
//       //   return;
//     }
//     return action();
//   }, [actionsAllowed, requiredAction, action]);
//   return deferredAction;
// };
