import { AvailableChainId, CHAIN_ID_TO_CHAIN_LOOKUP } from '@protocol/chains';
import React, { type FC } from 'react';
import { useSwitchChain } from 'wagmi';
import { useIsActionBlockedByChainForWallet } from '~src/hooks/wallet/useDeferActionToUnblockWalletState';
import { Box } from '~src/styled-system/jsx';
import {
  CompactDropdownItemButton,
  DropdownItemGroup,
  DropdownItemGroupGrid,
} from '../dropdown';
import { Popover, type PopoverPositioning } from '../popover';
import { DisplayP } from '../text';
import { SPANS_BY_CHAIN_ID } from './spans';
import { getAddChainParameterIfNeeded } from '~src/utils/chain/getAddChainParameter';

export const DeferToSwitchChainPopover: FC<{
  requiredChain: AvailableChainId;
  children: React.ReactNode;
  positioning?: PopoverPositioning;
}> = ({ requiredChain, children, positioning = { placement: 'top-end' } }) => {
  const isBlocked = useIsActionBlockedByChainForWallet(requiredChain);
  const { isPending, switchChain, ...rest } = useSwitchChain();

  if (isBlocked) {
    return (
      <Popover.Root positioning={positioning}>
        <Popover.Trigger asChild>
          <Box
            _open={{
              'opacity': '60',
              'cursor': 'not-allowed',
              '& > *': {
                pointerEvents: 'none',
              },
            }}
            transitionProperty={'opacity'}
            transitionDuration={'200ms'}
          >
            {React.Children.map(children, (child) =>
              React.cloneElement(child as React.ReactElement, {
                onClick: undefined,
              }),
            )}
          </Box>
        </Popover.Trigger>
        <Popover.Positioner>
          <Popover.Content>
            <DropdownItemGroupGrid>
              <DropdownItemGroup display={'flex'} flx={'flexRow'}>
                <DisplayP.Caption
                  color={'gray.500'}
                  px={'increment'}
                  py={'ten'}
                  whiteSpace={'nowrap'}
                >
                  To continue
                </DisplayP.Caption>
                <CompactDropdownItemButton
                  color={'gray.700'}
                  bg={'gray.100'}
                  fontWeight={'display.bold'}
                  onClick={() => {
                    // console.log('switching chain', requiredChain, switchChain);
                      switchChain({
                        chainId: requiredChain,
                        // addEthereumChainParameter: getAddChainParameterIfNeeded(requiredChain),
                      });
                  }}
                  disabled={isPending}
                >
                  {isPending ? 'Switching' : 'Switch to'}{' '}
                  {SPANS_BY_CHAIN_ID[requiredChain]}
                </CompactDropdownItemButton>
              </DropdownItemGroup>
            </DropdownItemGroupGrid>
          </Popover.Content>
        </Popover.Positioner>
      </Popover.Root>
    );
  }

  return children;
};
