import { motion } from 'framer-motion';
import { css } from '~src/styled-system/css';
import { styled } from '~src/styled-system/jsx';
import { styledMotionOpts } from '../../utils/panda/motion';

export const layeredBodyCss = css.raw({
  borderRadius: 'box',
  py: 'boxGap',
  px: 'boxGap',
  gap: 'boxGap',
  position: 'relative',
  background: 'darken',
});

export const layeredBoxGroupCss = css.raw({
  borderRadius: 'innerGap',
  gap: 'thinGap',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  boxShadow: 'soft',
});

export const layeredThinBoxGroupCss = css.raw({
  display: 'grid',
  gridTemplateColumns: '[1fr]',
  borderWidth: 'halfThinGap',
  borderStyle: 'solid',
  gap: 'halfThinGap',
  overflow: 'hidden',
  bg: 'gray.100',
  borderColor: 'gray.100',
  rounded: 'innerBox',
});

export const layeredGridGroupCss = css.raw({
  display: 'grid',
  gap: 'thinGap',
});

export const layeredFlexColumnGroupCss = css.raw({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  gap: 'thinGap',
});

export const LayeredBody = styled(
  motion.div,
  {
    base: layeredBodyCss,
  },
  styledMotionOpts,
);

export const LayeredBoxGroup = styled(
  motion.div,
  {
    base: layeredBoxGroupCss,
    variants: {
      isDark: {
        true: {
          background: 'darken.025',
        },
        false: {
          background: 'lighten.03',
        },
      },
    },
  },
  styledMotionOpts,
);

export const LayeredThinBoxGroup = styled(
  motion.div,
  {
    base: layeredThinBoxGroupCss,
  },
  styledMotionOpts,
);

export const LayeredGridGroup = styled(
  motion.div,
  {
    base: layeredGridGroupCss,
  },
  styledMotionOpts,
);

export const LayeredFlexColumnGroup = styled(
  motion.div,
  {
    base: layeredFlexColumnGroupCss,
  },
  styledMotionOpts,
);

export const layeredBoxBaseCss = css.raw({
  rounded: 'innerBox',
  background: 'white-pane',
  padding: {
    base: 'box',
    xs: 'largestBox',
  },
});

export const LayeredBox = styled(
  motion.div,
  {
    base: layeredBoxBaseCss,
    variants: {
      variant: {
        medium: {
          padding: {
            base: 'box',
            xs: 'mediumBox',
          },
        },
        short: {
          py: 'box',
          px: 'largestBox',
        },
      },
    },
    defaultVariants: {},
  },
  styledMotionOpts,
);
