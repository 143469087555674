import {
  HiraethChainProps,
  hiraethMultiChainProps,
  type MultiVersionMapEvmOptions,
} from '@hiraeth/art';
import {
  HiraethProtocolParameters,
  hiraethProtocolParametersByChain,
} from '@hiraeth/protocol';
import { SupportedBlockverseChainId } from '@pob/blockverse';
import type { TRPCClientErrorLike } from '@trpc/client';
import type { UseTRPCQueryResult } from '@trpc/react-query/shared';
import React, { createContext, useContext, useMemo } from 'react';
import { trpc } from '~src/clients/trpc-client';
import { CONTEXT_DEFAULT_OBJ } from '~src/constants/defaults';
import { QUERY_LIVENESS } from '~src/constants/query';
import { usePreferredChainId } from '~src/providers/PreferredNetwork';
import type { AppRouter, RouterOutputs } from '~src/server/trpc/routers';

type HiraethChainStateContextType = {
  chainProps: HiraethChainProps<MultiVersionMapEvmOptions> | undefined | null;
  hiraethProtocolParameters: HiraethProtocolParameters | undefined | null;
  mintingStateRes: UseTRPCQueryResult<
    RouterOutputs['hiraeth']['global']['state'],
    TRPCClientErrorLike<AppRouter>
  >;
  refreshHiraethState: () => Promise<void>;
};

export const useHiraethChainStateForChain = (
  chainId: SupportedBlockverseChainId<'hiraeth'> | undefined,
) => {
  const chainProps = useMemo(() => {
    if (!chainId) {
      return undefined;
    }
    return hiraethMultiChainProps[chainId];
  }, [chainId]);

  const hiraethProtocolParameters = useMemo(() => {
    if (!chainId) {
      return undefined;
    }
    return hiraethProtocolParametersByChain[chainId];
  }, [chainId]);

  const mintingStateRes = trpc.hiraeth.global.state.useQuery(
    {
      chainId: chainId!,
    },
    {
      ...QUERY_LIVENESS['slow-block'],
      enabled: !!chainId,
    },
  );

  const refreshHiraethState = async () => {
    await mintingStateRes.refetch();
  };

  return useMemo(
    () => ({
      chainProps,
      mintingStateRes,
      hiraethProtocolParameters,
      refreshHiraethState,
    }),
    [chainProps, mintingStateRes, hiraethProtocolParameters],
  );
};

export const HiraethChainStateContext =
  createContext<HiraethChainStateContextType>({
    chainProps: undefined,
    mintingStateRes: CONTEXT_DEFAULT_OBJ,
    hiraethProtocolParameters: undefined,
    refreshHiraethState: () => Promise.resolve(),
  });

export type HiraethChainStateProviderProps = {};

export const useHiraethChainState = () => {
  const context = useContext(HiraethChainStateContext);
  if (!context) {
    throw new Error(
      'useHiraethChainState must be used within a HiraethChainStateProvider',
    );
  }
  return context;
};

export const HiraethChainStateProvider = ({
  children,
}: HiraethChainStateProviderProps & { children: React.ReactNode }) => {
  const chainId = usePreferredChainId<SupportedBlockverseChainId<'hiraeth'>>();
  const state = useHiraethChainStateForChain(chainId);

  return (
    <HiraethChainStateContext.Provider value={state}>
      {children}
    </HiraethChainStateContext.Provider>
  );
};
