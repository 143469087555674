import type { Evm } from '@pob/shared';
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';
import { CONTEXT_DEFAULT_FUNCTION } from '../../../../constants/defaults';

export type HiraethHoverType =
  | {
      type: 'crest';
      address: Evm.Address;
    }
  | {
      type: 'txnType';
    }
  | {
      type: 'log';
      topic: Evm.Word;
    }
  | {
      type: 'transfer';
      address: Evm.Address;
    };

type HiraethHoverStateContextType = {
  hoverState: HiraethHoverType | null;
  setHoverState: Dispatch<SetStateAction<HiraethHoverType | null>>;
};

export const HiraethHoverStateContext =
  createContext<HiraethHoverStateContextType>({
    hoverState: null,
    setHoverState: CONTEXT_DEFAULT_FUNCTION,
  });

export type HiraethHoverStateProviderProps = {};

export const useHiraethHoverState = () => {
  const context = useContext(HiraethHoverStateContext);
  if (!context) {
    throw new Error(
      'useHiraethHoverState must be used within a HiraethHoverStateProvider',
    );
  }
  return context;
};

export const HiraethHoverStateProvider = ({
  children,
}: HiraethHoverStateProviderProps & { children: React.ReactNode }) => {
  const [hoverState, setHoverState] = useState<HiraethHoverType | null>(null);
  const state = useMemo(() => {
    return {
      hoverState,
      setHoverState,
    };
  }, [hoverState]);

  return (
    <HiraethHoverStateContext.Provider value={state}>
      {children}
    </HiraethHoverStateContext.Provider>
  );
};
