import { useMemo } from 'react';
import { usePreferredChain } from '~src/providers/PreferredNetwork';
import {
  getBlockExplorerAddressUrl,
  getBlockExplorerBlockUrl,
  getBlockExplorerCollectionUrl,
  getBlockExplorerTxUrl,
} from '~src/utils/urls/block-explorer';
import { getOpenSeaUrl } from '~src/utils/urls/marketplace';
import { Evm } from '@pob/shared';

export const useOpenSeaCollectionUrl = (
  collectionAddress?: Evm.Address,
  tokenId: string = '0',
) => {
  const chain = usePreferredChain();
  return useMemo(() => {
    if (!chain) {
      return undefined;
    }
    if (!collectionAddress) {
      return undefined;
    }
    return getOpenSeaUrl(chain.id, collectionAddress, tokenId);
  }, [chain, collectionAddress, tokenId]);
};

export const useBlockExplorerTokenUrl = (
  collectionAddress?: Evm.Address | null,
  tokenId: string = '0',
) => {
  const chain = usePreferredChain();
  return useMemo(() => {
    if (!chain) {
      return undefined;
    }
    if (!collectionAddress) {
      return undefined;
    }
    if (!chain.blockExplorers) {
      return undefined;
    }
    return getBlockExplorerCollectionUrl(
      chain.blockExplorers.default.url,
      collectionAddress,
      // tokenId,
    );
  }, [chain, collectionAddress, tokenId]);
};

export const useBlockExplorerTxUrl = (txHash?: Evm.TxnHash) => {
  const chain = usePreferredChain();
  return useMemo(() => {
    if (!chain) {
      return undefined;
    }
    if (!txHash) {
      return undefined;
    }
    if (!chain.blockExplorers) {
      return undefined;
    }
    return getBlockExplorerTxUrl(chain.blockExplorers.default.url, txHash);
  }, [chain, txHash]);
};

export const useBlockExplorerAddressUrl = (address?: Evm.Address) => {
  const chain = usePreferredChain();
  return useMemo(() => {
    if (!chain) {
      return undefined;
    }
    if (!address) {
      return undefined;
    }
    if (!chain.blockExplorers) {
      return undefined;
    }
    return getBlockExplorerAddressUrl(
      chain.blockExplorers.default.url,
      address,
    );
  }, [chain, address]);
};

export const useBlockExplorerBlockUrl = (blockNumber?: string) => {
  const chain = usePreferredChain();
  return useMemo(() => {
    if (!chain) {
      return undefined;
    }
    if (!blockNumber) {
      return undefined;
    }
    if (!chain.blockExplorers) {
      return undefined;
    }
    return getBlockExplorerBlockUrl(
      chain.blockExplorers.default.url,
      blockNumber,
    );
  }, [chain, blockNumber]);
};
