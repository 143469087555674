import { AnimatePresence } from 'framer-motion';
import { FC, useCallback, useEffect, useState } from 'react';
import { useCopyToClipboard } from 'react-use';
import { A } from '~src/components/anchor';
import { BaseButton } from '~src/components/button';
import { CopyIcon } from '~src/components/icon/copy';
import { DisplayP } from '~src/components/text';
import { USE_VARIANT_PROPS } from '~src/constants/framer';
import { useBlockExplorerTxUrl } from '~src/hooks/url/useExternalUrls';
import { HStack } from '~src/styled-system/jsx';
import { getShortenedTxn } from '~src/utils/bytes/shorten';
import { useHiraeth } from '../../../providers/Hiraeth';
import {
  BaseHiraethRow,
  HiraethRowLabel,
  HiraethRowLabelContainer,
  HiraethRowValue,
} from './common';
import { Evm } from '@pob/shared';

export const HiraethTxnHashRow: FC = () => {
  const { txnHash } = useHiraeth();
  return <StatelessHiraethTxnHashRow txnHash={txnHash} />;
};

export const StatelessHiraethTxnHashRow: FC<{ txnHash: Evm.TxnHash }> = ({
  txnHash,
}) => {
  const blockExplorerUrl = useBlockExplorerTxUrl(txnHash);
  return (
    <BaseHiraethRow>
      <HiraethRowLabelContainer>
        <HiraethRowLabel>Transaction hash</HiraethRowLabel>
      </HiraethRowLabelContainer>
      <HStack gap={'threeQuartersIncrement'}>
        <HiraethRowValue>
          <A hover={'underline'} href={blockExplorerUrl} target={'_blank'}>
            {getShortenedTxn(txnHash)}
          </A>
        </HiraethRowValue>
        <CopyHash />
      </HStack>
    </BaseHiraethRow>
  );
};

const CopyHash: FC = () => {
  const { txnHash } = useHiraeth();
  const [, copyToClipboard] = useCopyToClipboard();
  const [copied, setCopied] = useState<boolean>(false);
  const copyClick = useCallback(() => {
    copyToClipboard(txnHash);
    setCopied(true);
  }, [txnHash]);
  useEffect(() => {
    let clearToken: number | undefined = undefined;
    if (copied) {
      clearToken = window.setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
    return () => {
      clearTimeout(clearToken);
    };
  }, [copied]);
  return (
    <>
      <BaseButton
        p={'1'}
        color={'gray.400'}
        css={{
          '& > svg': {
            width: 'icon.twelve',
            height: 'icon.twelve',
            display: 'block',
          },
        }}
        onClick={copyClick}
      >
        <CopyIcon />
      </BaseButton>
      <AnimatePresence initial={false}>
        {copied ? (
          <DisplayP.Caption
            {...USE_VARIANT_PROPS}
            variants={{
              initial: {
                x: 8,
                opacity: 0,
              },
              animate: {
                x: 0,
                opacity: 1,
              },
              exit: {
                x: 8,
                opacity: 0,
              },
            }}
            color={'gray.400'}
          >
            Copied!
          </DisplayP.Caption>
        ) : null}
      </AnimatePresence>
    </>
  );
};
