import { LayeredBox } from '~src/components/box/layered';
import { styled } from '~src/styled-system/jsx';

export const DetailsBox = styled(LayeredBox, {
  base: {
    bg: 'white',
    border: '1px solid',
    borderColor: 'gray.100',
    p: 'innerBox',
  },
});

export const SkinnyDetailsBox = styled(DetailsBox, {
  base: {
    p: 'zero',
  },
});

export const TableDetailsBox = styled(DetailsBox, {
  base: {
    // border: 'none',
    p: 'zero',
    gap: 'halfThinGap',
    bg: 'gray.100',
    display: 'grid',
    gridTemplateColumns: '1',
    overflow: 'hidden',
  },
});

export const DetailsWellBox = styled(LayeredBox, {
  base: {
    bg: 'transparent',
    p: 'zero',
  },
});
