import type { Evm } from '@pob/shared';
import { mintingMulticallAbi } from '@protocol/core';
import { useRouter } from 'next/router';
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import {
  useAccount,
  useWaitForTransactionReceipt,
  useWriteContract,
  type Config,
  type UseWriteContractReturnType,
} from 'wagmi';
import {
  CONTEXT_DEFAULT_FUNCTION,
  CONTEXT_DEFAULT_OBJ,
} from '~src/constants/defaults';
import {
  usePreferredAddress,
  usePreferredChain,
  usePreferredWalletClientState,
} from '~src/providers/PreferredNetwork';
import type { SetStateFromHook } from '~src/types/common';
import { isTransactionRejectedByUser } from '~src/utils/errors';
import { prettifyTokenValue } from '~src/utils/number/prettify';
import { useIncorrectNetworkError } from '../hooks/errors/useIncorrectNetworkError';
import { useInsufficientFundsError } from '../hooks/errors/useInsufficientFundsError';
import { useLinkedAccountMismatchWarning } from '../hooks/errors/useLinkedAccountMismatchWarning';
import {
  useOnChainCalls,
  useOnChainCallsGasLimit,
} from '../hooks/useOnChainCalls';
import {
  CheckoutItem,
  CheckoutMintProps,
  CheckoutMode,
  CheckoutPriceProps,
} from '../types';

import { type CheckoutErrorMap } from '../types/errors';

export type CheckoutContext = 'mini-app' | 'dapp';

type CheckoutContextType = {
  price: CheckoutPriceProps | undefined;

  errors: Partial<CheckoutErrorMap>;

  mode: CheckoutMode;

  stateInflow: CheckoutFlowState;
  setStateInflow: SetStateFromHook<CheckoutFlowState>;

  isModalOpen: boolean;
  setModalOpen: SetStateFromHook<boolean>;

  checkoutItems: CheckoutItem[] | null;

  writeContract: UseWriteContractReturnType<Config, unknown>;

  isCheckoutDisabled: boolean;
  isMintDisabled: boolean;

  mint: () => Promise<void>;
  mintGasLimit: bigint | undefined;
  mintTxnHash: Evm.TxnHash | undefined;

  resetCheckout: () => void;

  onCheckoutOpen: (props: { prepareCheckoutOnOpen?: () => void }) => void;

  checkoutContext: CheckoutContext;
};

export const CheckoutContext = createContext<CheckoutContextType>({
  price: undefined,
  errors: {},
  mode: 'cart',
  isModalOpen: false,
  setModalOpen: CONTEXT_DEFAULT_FUNCTION,
  stateInflow: 'checkout',
  setStateInflow: CONTEXT_DEFAULT_FUNCTION,
  checkoutItems: null,
  writeContract: CONTEXT_DEFAULT_OBJ,
  isCheckoutDisabled: true,
  isMintDisabled: true,
  mint: CONTEXT_DEFAULT_FUNCTION,
  mintGasLimit: undefined,
  mintTxnHash: undefined,
  resetCheckout: CONTEXT_DEFAULT_FUNCTION,
  onCheckoutOpen: CONTEXT_DEFAULT_FUNCTION,
  checkoutContext: 'dapp',
});

export type CheckoutFlowState = 'checkout' | 'receipt';
export type CheckoutProviderProps = {
  mode: CheckoutMode;
  price: CheckoutPriceProps | undefined;
  errors: Partial<CheckoutErrorMap>;

  mintProps: CheckoutMintProps | undefined;

  checkoutItems: CheckoutItem[] | null;
  shouldUseDedicatedReceiptPage?: boolean;

  checkoutContext?: CheckoutContext;
};

export const useCheckoutContext = () => {
  const context = useContext(CheckoutContext);
  if (!context) {
    throw new Error('useCheckoutContext must be used within a CheckoutContext');
  }
  return context;
};

export const CheckoutProvider = ({
  children,
  mode,
  price,
  errors: errorsFromProps,
  checkoutItems,
  mintProps,
  shouldUseDedicatedReceiptPage = false,
  checkoutContext = 'dapp',
}: CheckoutProviderProps & { children: React.ReactNode }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [stateInflow, setStateInflow] = useState<CheckoutFlowState>('checkout');
  const router = useRouter();

  const { address } = useAccount();
  const preferredChain = usePreferredChain();
  const walletClientState = usePreferredWalletClientState();
  const writeContract = useWriteContract();

  // errors
  const incorrectNetworkError = useIncorrectNetworkError(price);
  const insufficientFundsError = useInsufficientFundsError(price);
  const linkedAccountMismatchWarning = useLinkedAccountMismatchWarning();
  const errorsWithoutMintErrors = useMemo(
    () => ({
      ...errorsFromProps,
      ...(incorrectNetworkError
        ? {
            'incorrect-network': incorrectNetworkError,
          }
        : {}),
      ...(linkedAccountMismatchWarning
        ? {
            'linked-account-mismatch': linkedAccountMismatchWarning,
          }
        : {}),
      ...(insufficientFundsError
        ? ({
            'insufficient-funds': insufficientFundsError,
          } satisfies Partial<CheckoutErrorMap>)
        : {}),
    }),
    [
      errorsFromProps,
      incorrectNetworkError,
      insufficientFundsError,
      linkedAccountMismatchWarning,
    ],
  );

  // mutations
  const mintingMulticall = usePreferredAddress((d) => d.utils.mintingMultiCall);

  // mint related information
  const mintCallsRes = useOnChainCalls(mintProps);
  const mintGasLimit = useOnChainCallsGasLimit(mintCallsRes.data);
  const [mintTxnHash, setMintTxHash] = useState<Evm.TxnHash | undefined>(
    undefined,
  );

  const mint = useCallback(async () => {
    if (!mintCallsRes.data) {
      return;
    }
    if (!mintingMulticall) {
      return;
    }
    const mintCalls = mintCallsRes.data;
    const mintCallArgs = mintCalls.map((c) => c.args);
    const totalValue = mintCalls.reduce((acc, call) => {
      return acc + call.args.value;
    }, 0n);

    console.log(
      'Minting with calls:',
      mintCalls,
      'totalValue:',
      totalValue,
      prettifyTokenValue(totalValue),
      'mintGasLimit:',
      mintGasLimit,
    );

    const writeContractAsync = writeContract.writeContractAsync;

    const txnHash = await writeContractAsync({
      address: mintingMulticall,
      abi: mintingMulticallAbi,
      functionName: 'safeAggregate3Value',
      args: [mintCallArgs],
      value: totalValue,
      gas: mintGasLimit,
    });
    setMintTxHash(txnHash as Evm.TxnHash);

    if (shouldUseDedicatedReceiptPage) {
      console.log('DEPRECATED: use modal instead');
      // router.push({
      //   pathname: '/checkout/receipt/[chainNetwork]/[txnHash]',
      //   query: {
      //     chainNetwork: getChainNetwork(preferredChain!.id),
      //     txnHash,
      //   },
      // });
    } else {
      setStateInflow('receipt');
    }
  }, [
    mintCallsRes.data,
    mintingMulticall,
    mintGasLimit,
    writeContract,
    shouldUseDedicatedReceiptPage,
    preferredChain,
    router,
  ]);

  const transactionReceiptRes = useWaitForTransactionReceipt({
    hash: mintTxnHash,
  });

  // disabled states
  const isCheckoutDisabled = useMemo(() => {
    return (
      // walletClientState === 'not-preferred-chain' ||
      !checkoutItems ||
      checkoutItems.length === 0 ||
      !!Object.values(errorsWithoutMintErrors).find(
        (e) => e?.isCheckoutBlocking,
      )
    );
  }, [walletClientState, checkoutItems, errorsWithoutMintErrors]);

  const isMintDisabled = useMemo(() => {
    if (walletClientState === 'not-preferred-chain') {
      return true;
    }
    if (!address) {
      return true;
    }
    if (!!insufficientFundsError) {
      return true;
    }
    if (!mintProps) {
      return true;
    }
    if (!!mintTxnHash && transactionReceiptRes.isPending) {
      return true;
    }
    if (writeContract?.isPending) {
      return true;
    }
    // errors are handled via errors
    if (writeContract?.isError) {
      return true;
    }
    if (isCheckoutDisabled) {
      return true;
    }
    return false;
  }, [
    mintProps,
    walletClientState,
    writeContract,
    isCheckoutDisabled,
    address,
    insufficientFundsError,
    transactionReceiptRes,
    mintTxnHash,
  ]);

  const resetCheckout = useCallback(() => {
    setStateInflow('checkout');
    writeContract.reset();
  }, [writeContract]);

  const onMintError = useMemo(():
    | CheckoutErrorMap['txn-failure']
    | undefined => {
    if (!writeContract?.error) {
      return undefined;
    }

    if (
      writeContract.isError &&
      isTransactionRejectedByUser(writeContract.error as any)
    ) {
      return {
        source: 'global',
        type: 'txn-failure',
        isCheckoutBlocking: true,
        isMintBlocking: true,
        actionLabel: 'Retry',
        action: resetCheckout,
        message: 'Transaction was rejected by user',
        errorType: 'cancelled',
        hideActionOnMint: false,
      };
    }

    return {
      source: 'global',
      type: 'txn-failure',
      message: writeContract.error.message ?? 'UNKNOWN',
      isMintBlocking: true,
      errorType: 'unknown',
      isCheckoutBlocking: true,
      actionLabel: 'Retry',
      action: resetCheckout,
      hideActionOnMint: false,
    };
  }, [writeContract, resetCheckout]);

  const errors = useMemo(() => {
    const errorsWithUndefined = {
      ...errorsWithoutMintErrors,
      ...(onMintError
        ? {
            'txn-failure': onMintError,
          }
        : {}),
    } satisfies Partial<CheckoutErrorMap>;
    // delete any undefined values
    for (const key in errorsWithUndefined) {
      if ((errorsWithUndefined as any)[key] === undefined) {
        delete (errorsWithUndefined as any)[key];
      }
    }
    return errorsWithUndefined;
  }, [errorsWithoutMintErrors, onMintError]);

  const onCheckoutOpen = useCallback(
    (props: { prepareCheckoutOnOpen?: () => void }) => {
      console.log('onCheckoutOpen', props);
      props.prepareCheckoutOnOpen?.();
      setModalOpen(true);
    },
    [],
  );

  const state = useMemo(() => {
    return {
      mode,
      price,
      errors,
      checkoutItems,

      stateInflow,
      setStateInflow,

      mintGasLimit,
      writeContract,

      isMintDisabled,
      isCheckoutDisabled,

      isModalOpen,
      setModalOpen,

      mint,
      mintStatus: !mintTxnHash ? undefined : transactionReceiptRes.status,
      mintTxnHash,

      resetCheckout,

      onCheckoutOpen,

      checkoutContext,
    };
  }, [
    isMintDisabled,
    mint,
    writeContract,
    mintGasLimit,
    checkoutItems,
    stateInflow,
    price,
    errors,
    mode,
    isModalOpen,
    transactionReceiptRes.status,
    mintTxnHash,
    resetCheckout,
    onCheckoutOpen,
    checkoutContext,
  ]);

  return (
    <CheckoutContext.Provider value={state}>
      {children}
    </CheckoutContext.Provider>
  );
};
