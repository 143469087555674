import { useMemo } from 'react';
import { useSwitchChain } from 'wagmi';
import {
  usePreferredChain,
  usePreferredWalletClientState,
} from '~src/providers/PreferredNetwork';
import type { CheckoutPriceProps } from '../../types';
import type { CheckoutErrorMap } from '../../types/errors';

export const useIncorrectNetworkError = (priceProps?: CheckoutPriceProps) => {
  const switchChainProps = useSwitchChain();
  const walletClientState = usePreferredWalletClientState();
  const preferredChain = usePreferredChain();

  const incorrectNetworkError = useMemo(():
    | CheckoutErrorMap['incorrect-network']
    | undefined => {
    if (switchChainProps.isPending) {
      return undefined;
    }
    // hide error if cart is empty
    if (!priceProps?.finalTotalPrice) {
      return undefined;
    }
    if (walletClientState === 'not-preferred-chain') {
      return {
        source: 'global',
        type: 'incorrect-network',
        actionLabel: 'Switch',
        action: () => {
          switchChainProps.switchChain?.({
            chainId: preferredChain.id,
          });
        },
        isMintBlocking: true,
        isCheckoutBlocking: false,
        hideActionOnMint: false,
        hideError: true,
      };
    }
    return undefined;
  }, [priceProps, walletClientState, switchChainProps, preferredChain]);
  return incorrectNetworkError;
};
