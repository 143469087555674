import type { TransactionExecutionError } from 'viem';

export const isTransactionExecutionError = (
  error: any,
): error is TransactionExecutionError => {
  return error?.name === 'TransactionExecutionError';
};

export const isTransactionRejectedByUser = (error: any): boolean => {
  if (!isTransactionExecutionError(error)) {
    return false;
  }
  if (error.message.includes('User rejected the request.')) {
    return true;
  }
  return false;
};
